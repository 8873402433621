import React, { useCallback, useEffect, useState, useRef } from 'react'
import Grid from '@mui/material/Grid2'
import { Uppy as UppyCore } from '@uppy/core'
import PropTypes from 'prop-types'
import PersonalInformationFields from './PersonalInformationFields'
import ProgramInformationFields from './ProgramInformationFields'
import { introValidation } from './functions/introValidation'
import { splitTimeCodeValidation } from './functions/splitTimecodeValidation'
import { segmentValidation } from './functions/segmentValidation'
import {
  emailValidation, firstNameValidation, lastNameValidation,
  phoneNumberValidation, houseIdValidation,
} from './functions/personalInformationValidation'
import SegmentFields from './SegmentFields'
import LoadingButton from '@mui/lab/LoadingButton'

export default function MetadataForm({ uppy, setDashboardModalOpen }) {
  const [allIsValid, setAllIsValid] = useState(false)
  const [firstName, setFirstName] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [lastName, setLastName] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [email, setEmail] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [phoneNumber, setPhoneNumber] = useState({
    value: '',
    error: false,
    errorMessage: '',
    required: false,
  })
  const [houseId, setHouseId] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [splitTimecode, setSplitTimecode] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [introStart, setIntroStart] = useState({
    value: '',
    error: false,
    errorMessage: '',
    required: false,
  })
  const [introEnd, setIntroEnd] = useState({
    value: '',
    error: false,
    errorMessage: '',
    required: false,
  })
  const [programInformation, setProgramInformation] = useState([
    {
      segmentType: 'Start',
      value: '00:00:00:00',
      error: false,
      errorMessage: '',
      id: 0,
      required: true,
    },
    {
      segmentType: 'End',
      value: null,
      error: null,
      errorMessage: '',
      id: 0,
      required: true,
    },
  ])
  const prevSegmentLength = useRef(programInformation.length)

  const validateFirstName = useCallback(() => {
    firstNameValidation({ firstName, setFirstName })
  }, [firstName])

  const validateLastName = useCallback(() => {
    lastNameValidation({ lastName, setLastName })
  }, [lastName])

  const validateEmail = useCallback(() => {
    emailValidation({ email, setEmail })
  }, [email])

  const validatePhoneNumber = useCallback(() => {
    phoneNumberValidation({ phoneNumber, setPhoneNumber })
  }, [phoneNumber])

  const validateHouseId = useCallback(() => {
    houseIdValidation({ houseId, setHouseId })
  }, [houseId])

  const validateIntroPosition = useCallback(({ validateOther, position }) => {
    introValidation({
      introStart, introEnd, setIntroStart, setIntroEnd,
      validateOther, position, programInformation
    })
  }, [introEnd, introStart, programInformation])

  const validateSplitTimecode = useCallback(() => {
    splitTimeCodeValidation({ splitTimecode, setSplitTimecode, programInformation })
  }, [splitTimecode, programInformation])

  const validateAllSegments = useCallback(() => {
    segmentValidation({ programInformation, setProgramInformation, setSplitTimecode, splitTimecode })
  }, [programInformation, splitTimecode])

  // useEffect used to reset segment validation when a segment is removed
  useEffect(() => {
    if (prevSegmentLength.current === programInformation.length) return
    if (prevSegmentLength.current < programInformation.length) {
      prevSegmentLength.current = programInformation.length
      return
    }

    prevSegmentLength.current = programInformation.length
    validateAllSegments()
  }, [programInformation.length, validateAllSegments])

  useEffect(() => {
    const isValidFirstName = firstName.error === false
    const isValidLastName = lastName.error === false
    const isValidEmail = email.error === false
    const isValidPhoneNumber = phoneNumber.error === false
    const isValidHouseId = houseId.error === false
    const isValidSplitTimecode = splitTimecode.error === false
    const isValidIntroStart = introStart.error === false
    const isValidIntroEnd = introEnd.error === false
    const isValidProgramInformation = programInformation.every(
      (segment) => segment.error === false
    )
    const allValid =
      isValidFirstName &&
      isValidLastName &&
      isValidEmail &&
      isValidPhoneNumber &&
      isValidHouseId &&
      isValidSplitTimecode &&
      isValidIntroStart &&
      isValidIntroEnd &&
      isValidProgramInformation

    const metadata = {
      email: email.value,
      house_id: houseId.value,
      lastName: lastName.value,
      firstName: firstName.value,
      intro_end: introEnd.value,
      intro_start: introStart.value,
      phoneNumber: phoneNumber.value,
      split_timecode: splitTimecode.value,
    }
    programInformation.forEach(segment => {
      const key = 'Segment' + (segment.id + 1) + segment.segmentType
      metadata[key] = segment.value
    })
    // Conscious decision to use uppy.setState instead of uppy.setMeta
    // as when using uppy.setMeta and then adding new segment and then deleting it
    // caused the deleted segment metadata to not be removed from the uppy state.
    uppy.setState({...uppy.getState(), meta: metadata})
    setAllIsValid(allValid)
  }, [email.value, email.error, firstName.value, firstName.error, houseId.value, houseId.error,
    introEnd.value, introEnd.error, introStart.value, introStart.error, lastName.value, lastName.error,
    phoneNumber.value, phoneNumber.error, programInformation, splitTimecode.value, splitTimecode.error, uppy])

  useEffect(() => {
    // check if the Form plugin is already installed
    if (uppy.getPlugin('Form')) return
  })

  return (
      <Grid size={12}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <PersonalInformationFields
              firstName={firstName}
              setFirstName={setFirstName}
              validateFirstName={validateFirstName}
              lastName={lastName}
              setLastName={setLastName}
              validateLastName={validateLastName}
              email={email}
              setEmail={setEmail}
              validateEmail={validateEmail}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              validatePhoneNumber={validatePhoneNumber}
            />
          </Grid>
          <Grid size={12}>
            <SegmentFields
              programInformation={programInformation}
              setProgramInformation={setProgramInformation}
              validateAllSegments={validateAllSegments}
            />
          </Grid>
          <Grid size={12}>
            <ProgramInformationFields
              houseId={houseId}
              setHouseId={setHouseId}
              validateHouseId={validateHouseId}

              splitTimecode={splitTimecode}
              setSplitTimecode={setSplitTimecode}
              validateSplitTimecode={validateSplitTimecode}

              introStart={introStart}
              setIntroStart={setIntroStart}
              validateIntroStart={() => validateIntroPosition({ validateOther: true, position: 'start' })}

              introEnd={introEnd}
              setIntroEnd={setIntroEnd}
              validateIntroEnd={() => validateIntroPosition({ validateOther: true, position: 'end' })}
            />
          </Grid>
          <LoadingButton
            variant="action"
            onClick={() => setDashboardModalOpen(true)}
            data-cy="add-file-button"
            disabled={!allIsValid}
            fullWidth
          >
            Select file and upload
          </LoadingButton>
        </Grid>
      </Grid>
  )
}

MetadataForm.propTypes = {
  uppy: PropTypes.instanceOf(UppyCore).isRequired,
  setDashboardModalOpen: PropTypes.func.isRequired,
}
