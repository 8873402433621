import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import PrivateRoute from './ui/components/PrivateRoute'
import LoginPage from './ui/views/LoginPage'
import UploadPage from './ui/views/UploadPage'
import theme from './theme'
import Layout from './ui/layout'

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <Layout>
          <Router>
            <Routes>
              <Route
                path="/home"
                element={
                  <PrivateRoute>
                    <UploadPage />
                  </PrivateRoute>
                }
              />
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </Router>
        </Layout>
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
