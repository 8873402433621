import React, { useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2'
import Uppy from '@uppy/core'
import { Dashboard, DashboardModal } from '@uppy/react'
import Tus from '@uppy/tus'
import AuthContext from '../../context/AuthContext'
import MetadataForm from '../metadata'
import SanitizeHTML from '../components/SanitizeHTML'

// And their styles (for UI plugins)
// With webpack and `style-loader`, you can import them like this:
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import UppyCustomStyles from '../components/UppyCustomStyles'
import UploadCompletedDialog from '../components/UploadCompletedDialog'

export default function UploadPage() {
  const [dashboardModalOpen, setDashboardModalOpen] = useState(false)
  const [uppyUploadResponse, setUppyUploadResponse] = useState()
  const [metadataFormKey, setMetadataFormKey] = useState('metadata-form-key')

  // IMPORTANT: passing an initializer function to prevent the state from recreating.
  // https://uppy.io/docs/react/
  const [uppy] = useState(() => {
    return new Uppy()
  })

  const { user, refreshAuthToken, logoutUser, isAccessTokenExpired } = useContext(AuthContext)
  const { show_metadata_form, username, description } = user

  useEffect(() => {
    uppy.setOptions({
      restrictions: {
        minNumberOfFiles: 1,
        maxNumberOfFiles: show_metadata_form ? 1 : null,
        allowedFileTypes: show_metadata_form ? ['.mxf'] : null,
        minFileSize: 1,
      },
      meta: {
        username,
      },
    })

    if (!uppy.getPlugin('Tus')) {
      uppy.use(Tus, {
        endpoint: '/api/upload/',
        chunkSize: 20971520,
        limit: 5, // max number of parallel uploads, eg. rest of the files will wait until one of the 5 uploads is finished until starting.
        removeFingerprintOnSuccess: true,
        retryDelays: [0, 2000],
        async onBeforeRequest(req) {
          if (isAccessTokenExpired()) {
            await refreshAuthToken()
          }
          const token = JSON.parse(localStorage.getItem('authTokens'))?.access
          req.setHeader('Authorization', `Bearer ${token}`)
        },
        onShouldRetry(err, retryAttempt, options, next) {
          const status = err?.originalResponse?.getStatus()

          if (status === 401) {
            const body = err?.originalResponse?.getBody()
            let parsedBody = body ? JSON.parse(body) : undefined
            // If user has been deleted from the admin panel, this will be returned
            if (parsedBody?.code === 'user_not_found') {
              logoutUser()
              return false
            }
            // Session has expired
            return true
          }
          return next(err)
        },
        async onAfterResponse(req, res) {
          if (res.getStatus() === 401) {
            await refreshAuthToken()
          }
          if (res.getStatus() === 422) {
            uppy.info(res.getBody(), 'error', 15000)
          }
        },
      })
    }

    // TODO: replace with useUppyEvent once updated to V4
    uppy.on('upload-success', (data) => {
      if (!show_metadata_form) return

      setUppyUploadResponse(data)
      setDashboardModalOpen(false)
      uppy.cancelAll()
    })
  }, [isAccessTokenExpired, logoutUser, refreshAuthToken, show_metadata_form, uppy, username])

  return (
    <>
      <UppyCustomStyles />
      {description && (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            color: 'text.secondary'
          }}
          size={12}
        >
          <SanitizeHTML html={description} />
        </Grid>
      )}
      {show_metadata_form ? (
        <>
          <DashboardModal
            uppy={uppy}
            open={dashboardModalOpen}
            onRequestClose={() => setDashboardModalOpen(false)}
            closeModalOnClickOutside
            showProgressDetails
            theme="dark"
            note="Only MXF files are accepted, Video: 1080i50, XDCAM HD422, 50Mbps, Audio: Uncompressed (PCM), Stereo, 48kHz"
          />
          <MetadataForm
            key={metadataFormKey}
            uppy={uppy}
            setDashboardModalOpen={setDashboardModalOpen}
          />
          {
            Boolean(uppyUploadResponse) && (
              <UploadCompletedDialog
                handleClose={() => {
                  setUppyUploadResponse(null)
                  setMetadataFormKey(new Date().getTime())
                }}
                uppyUploadResponse={uppyUploadResponse}
              />
            )
          }
        </>
      ) : (
        <Grid style={{ display: 'flex', justifyContent: 'center' }} size={12}>
          <Dashboard
            uppy={uppy}
            showProgressDetails
            theme="dark"
          />
        </Grid>
      )}
    </>
  )
}
