import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import PropTypes from 'prop-types'
import React from 'react'
import Footer from './Footer'
import Header from './Header'
import InfoMessage from '../components/InfoMessage'

export default function Layout({ children }) {
  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Header />
        <InfoMessage />
        {children}
        <Footer />
      </Grid>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
