import PropTypes, { string } from 'prop-types'
import React from 'react'
import InputMask from 'react-input-mask'
import TextField from '@mui/material/TextField'
export default function MaskedTextField({
  name = 'name',
  placeholder = 'placeholder',
  label = 'label',
  error = false,
  helperText = 'helperText',
  onBlur = () => {},
  disabled = false,
  required = false,
  mask,
  id,
  value,
  onChange,
}) {
  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      maskPlaceholder={null}
      disabled={disabled}
    >
      <TextField
        id={id}
        name={name}
        placeholder={placeholder}
        label={label}
        error={error}
        helperText={helperText}
        required={required}
        autoComplete="off"
        fullWidth
      />
    </InputMask>
  )
}

// require-default-props disabled because of the warning:
// Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead.
MaskedTextField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  mask: PropTypes.string.isRequired,
  id: string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
