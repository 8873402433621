import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)