import PropTypes from 'prop-types'
import React from 'react'
import { getFieldHelperText } from '../../utils/utils'
import TextField from '@mui/material/TextField'
export default function HouseIdField({ houseId, setHouseId, validateHouseId }) {
  return (
    <TextField
      id="house_id-input"
      name="house_id"
      label="House ID"
      onChange={(e) => setHouseId({ ...houseId, value: e.target.value })}
      onBlur={() => validateHouseId()}
      error={houseId.error}
      helperText={getFieldHelperText(houseId)}
      required
      fullWidth
    />
  )
}

HouseIdField.propTypes = {
  houseId: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setHouseId: PropTypes.func.isRequired,
  validateHouseId: PropTypes.func.isRequired,
}
