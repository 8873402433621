import PropTypes from 'prop-types'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'

export default function AddSegmentButton({ setProgramInformation }) {
  const addSegment = () => {
    setProgramInformation((old) => {
      const copy = [...old]
      const newStart = {
        segmentType: 'Start',
        value: null,
        error: null,
        id: copy.length / 2,
        required: true,
      }
      const newEnd = {
        segmentType: 'End',
        value: null,
        error: null,
        id: copy.length / 2,
        required: true,
      }
      copy.push(newStart, newEnd)
      return copy
    })
  }

  return (
    <Button
      fullWidth
      startIcon={<AddIcon />}
      color="secondary"
      onClick={addSegment}
      variant="outlined"
      data-cy="add-segment-button"
    >
      Add new segment
    </Button>
  )
}

AddSegmentButton.propTypes = {
  setProgramInformation: PropTypes.func.isRequired,
}
