export const firstNameValidation = ({ firstName, setFirstName }) => {
  if (!firstName.value || firstName.value === '') {
    setFirstName({
      ...firstName,
      error: true,
      errorMessage: 'First name is required',
    })
  } else {
    setFirstName({ ...firstName, error: false, errorMessage: '' })
  }
}

export const lastNameValidation = ({ lastName, setLastName }) => {
  if (!lastName.value || lastName.value === '') {
    setLastName({
      ...lastName,
      error: true,
      errorMessage: 'Last name is required',
    })
  } else {
    setLastName({ ...lastName, error: false, errorMessage: '' })
  }
}

export const emailValidation = ({ email, setEmail }) => {
  if (!email.value || email.value === '') {
    setEmail({ ...email, error: true, errorMessage: 'Email is required' })
  } else if (/^(.+)@(.+)\.(.+)$/.test(email.value)) {
    setEmail({ ...email, error: false, errorMessage: '' })
  } else {
    setEmail({
      ...email,
      error: true,
      errorMessage: 'Please enter a valid email address',
    })
  }
}

export const phoneNumberValidation = ({ phoneNumber, setPhoneNumber }) => {
  if (phoneNumber.value === '') {
    setPhoneNumber({ ...phoneNumber, error: false, errorMessage: '' })
  } else if (phoneNumber.value && phoneNumber.value !== '') {
    if (/^\+{1}\d{12}$|^\d{10}$/.test(phoneNumber.value)) {
      setPhoneNumber({ ...phoneNumber, error: false, errorMessage: '' })
    } else {
      setPhoneNumber({
        ...phoneNumber,
        error: true,
        errorMessage: 'Please enter a valid phone number or empty the field',
      })
    }
  }
}

export const houseIdValidation = ({ houseId, setHouseId }) => {
  if (!houseId.value || houseId.value === '') {
    setHouseId({
      ...houseId,
      error: true,
      errorMessage: 'House ID is required',
    })
  } else if (!/^\d+$/.test(houseId.value)) {
    setHouseId({
      ...houseId,
      error: true,
      errorMessage: 'House ID must be a number',
    })
  } else {
    const trimmedHouseId = houseId.value.trim()
    setHouseId({ ...houseId, value: trimmedHouseId, error: false, errorMessage: '' })
  }
}