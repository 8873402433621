import Grid from '@mui/material/Grid2'
import PropTypes from 'prop-types'
import React from 'react'
import { getFieldHelperText } from '../../utils/utils'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
export default function PersonalInformationFields({
  firstName,
  setFirstName,
  validateFirstName,
  lastName,
  setLastName,
  validateLastName,
  email,
  setEmail,
  validateEmail,
  phoneNumber,
  setPhoneNumber,
  validatePhoneNumber,
}) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant="h6">Personal Information</Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6
            }}>
            <TextField
              name="firstName"
              id="firstName-input"
              label="First Name"
              onChange={(e) =>
                setFirstName({ ...firstName, value: e.target.value })
              }
              onBlur={validateFirstName}
              error={firstName.error}
              helperText={getFieldHelperText(firstName)}
              fullWidth
              required
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6
            }}>
            <TextField
              name="lastName"
              id="lastName-input"
              label="Last Name"
              onChange={(e) => setLastName({ ...lastName, value: e.target.value })}
              onBlur={validateLastName}
              error={lastName.error}
              helperText={getFieldHelperText(lastName)}
              fullWidth
              required
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6
            }}>
            <TextField
              name="email"
              id="email-input"
              placeholder="example@domain.com"
              label="Email"
              onChange={(e) => setEmail({ ...email, value: e.target.value })}
              onBlur={validateEmail}
              error={email.error}
              helperText={getFieldHelperText(email)}
              fullWidth
              required
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6
            }}>
            <TextField
              name="phoneNumber"
              id="phoneNumber-input"
              placeholder="0123456789 or +358123456789"
              label="Phone Number"
              onChange={(e) =>
                setPhoneNumber({ ...phoneNumber, value: e.target.value })
              }
              onBlur={validatePhoneNumber}
              error={phoneNumber.error}
              helperText={getFieldHelperText(phoneNumber)}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

PersonalInformationFields.propTypes = {
  firstName: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setFirstName: PropTypes.func.isRequired,
  validateFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setLastName: PropTypes.func.isRequired,
  validateLastName: PropTypes.func.isRequired,
  email: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setEmail: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
  phoneNumber: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  validatePhoneNumber: PropTypes.func.isRequired,
}
