import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const itemKey = 'infoMessageClosed'

export default function InfoMessage() {
  const [isClosed, setIsClosed] = useState(() => localStorage.getItem(itemKey) === 'true')

  const handleClose = () => {
    setIsClosed(true)
    localStorage.setItem(itemKey, 'true')
  }

  if (isClosed) return null

  return (
    <Box sx={{ width: '600px', maxWidth: '100%', margin: '0 auto', marginBottom: '16px' }}>
      <Card sx={{ position: 'relative' }}>
        <CardContent>
          <IconButton sx={{ position: 'absolute', top: '8px', right: '8px' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" textAlign="center">
            New UI Update
          </Typography>
          <Typography textAlign="center" sx={{ marginTop: '8px' }}>
            We&apos;ve refreshed our user interface to align with our new company branding.
          </Typography>
          <Typography textAlign="center" sx={{ marginTop: '8px' }}>
            All functionality remains the same, but if you encounter any issues, feel free to reach out.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}