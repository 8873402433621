import propTypes from 'prop-types'
import React from 'react'
import AddSegmentButton from '../components/button/AddSegmentButton'
import DeleteSegmentButton from '../components/button/DeleteSegmentButton'

export default function AddOrDeleteSegment(props) {
  const { programInformation, setProgramInformation } = props
  return (
    <>
      {programInformation.length > 2 && (
        <DeleteSegmentButton
          setProgramInformation={setProgramInformation}
          index={programInformation.length - 2}
        />
      )}
      <AddSegmentButton setProgramInformation={setProgramInformation} />
    </>
  )
}

AddOrDeleteSegment.propTypes = {
  programInformation: propTypes.arrayOf(
    propTypes.shape({
      segmentType: propTypes.string.isRequired,
      value: propTypes.string,
      error: propTypes.bool,
      id: propTypes.number.isRequired,
      required: propTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  setProgramInformation: propTypes.func.isRequired,
}
