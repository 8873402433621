import React from 'react'
import GlobalStyles from '@mui/material/GlobalStyles'

// It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering. 
// This will ensure that the <style> tag generated would not recalculate on each render.
// https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override
const inputGlobalStyles = <GlobalStyles
  styles={(theme) => ({
    '.uppy-Dashboard-isFixed': {
      height: '100% !important'
    },
    '.uppy-Container': {
      maxWidth: '100% !important',
    },
    '.uppy-Root': {
      fontFamily: 'inherit !important',
    },
    '.uppy-Dashboard-inner': {
      border: '1px solid rgba(255, 255, 255, 0.12) !important',
      borderRadius: `${theme.shape.borderRadius}px !important`,
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
    '.uppy-Dashboard-AddFiles': {
      border: 'none !important'
    },
    '.uppy-Dashboard-dropFilesHereHint': {
      borderColor: `${theme.palette.brandSurfNTurf.main} !important`,
      backgroundImage: 'none !important',
      fontSize: '24px !important',
      paddingTop: '0 !important',
    },
    '.uppy-Dashboard-AddFiles-title': {
      color: `${theme.palette.text.primary} !important`,
    },
    '.uppy-Dashboard-browse': {
      color: `${theme.palette.secondary.main} !important`,
    },
    '.uppy-Dashboard-browse:hover': {
      borderBottom: `1px solid ${theme.palette.secondary.main} !important`,
    },
    '.uppy-Dashboard-browse:focus': {
      borderBottom: `1px solid ${theme.palette.secondary.main} !important`,
    },
    '.uppy-DashboardContent-back': {
      color: `${theme.palette.brandSeaNymph.main} !important`,
    },
    '.uppy-DashboardContent-addMore': {
      color: `${theme.palette.secondary.main} !important`,
    },
    '.uppy-StatusBar-actionBtn--upload': {
      width: '100% !important',
      backgroundColor: 'transparent !important',
      border: '2px solid !important',
      borderRadius: `${theme.shape.borderRadius}px !important`,
      borderColor: `${theme.palette.brandSurfNTurf.main} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.brandSurfNTurf.main} !important`,
      },
      transition: 'all 0.5s ease !important',
      color: `${theme.palette.text.primary} !important`,
      fontWeight: '600 !important',
      '&:disabled': {
        opacity: '0.5 !important',
      },
      textTransform: 'uppercase !important',
    },
    '.uppy-StatusBar-progress': {
      backgroundColor: `${theme.palette.brandForestFern.main} !important`,
    },
    '.uppy-StatusBar-spinner': {
      fill: `${theme.palette.brandForestFern.main} !important`,
    },
    '.uppy-Dashboard-Item-previewInnerWrap': {
      backgroundColor: 'rgb(255 255 255 / 3%) !important',
    },
    '.uppy-StatusBar-actionBtn--done': {
      color: `${theme.palette.secondary.main} !important`,
    },
    '.uppy-c-btn-primary:focus': {
      boxShadow: 'none! important',
    }
  })}
/>

export default function UppyCustomStyles() {
  return inputGlobalStyles
}