import Grid from '@mui/material/Grid2'
import PropTypes from 'prop-types'
import React from 'react'
import HouseIdField from './HouseIdField'
import SplitTimecodeField from './SplitTimecodeField'
import IntroField from './IntroField'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

export default function ProgramInformationFields({
  houseId, setHouseId, validateHouseId,
  splitTimecode, setSplitTimecode, validateSplitTimecode,
  introStart, setIntroStart, validateIntroStart,
  introEnd, setIntroEnd, validateIntroEnd,
}) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant="h6">Program Information</Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6
            }}>
            <HouseIdField
              houseId={houseId}
              setHouseId={setHouseId}
              validateHouseId={validateHouseId}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6
            }}>
            <SplitTimecodeField
              splitTimecode={splitTimecode}
              setSplitTimecode={setSplitTimecode}
              validateSplitTimecode={validateSplitTimecode}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6
            }}>
            <IntroField
              intro={introStart}
              setIntro={setIntroStart}
              validateIntro={validateIntroStart}
              position="Start"
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6
            }}>
            <IntroField
              intro={introEnd}
              setIntro={setIntroEnd}
              validateIntro={validateIntroEnd}
              position="End"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const inputFieldShape = {
  value: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
}

ProgramInformationFields.propTypes = {
  houseId: PropTypes.shape(inputFieldShape).isRequired,
  setHouseId: PropTypes.func.isRequired,
  validateHouseId: PropTypes.func.isRequired,

  splitTimecode: PropTypes.shape(inputFieldShape).isRequired,
  setSplitTimecode: PropTypes.func.isRequired,
  validateSplitTimecode: PropTypes.func.isRequired,

  introStart: PropTypes.shape(inputFieldShape).isRequired,
  setIntroStart: PropTypes.func.isRequired,
  validateIntroStart: PropTypes.func.isRequired,

  introEnd: PropTypes.shape(inputFieldShape).isRequired,
  setIntroEnd: PropTypes.func.isRequired,
  validateIntroEnd: PropTypes.func.isRequired,
}
