import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

// https://docs.google.com/presentation/d/1MX5QyiORbF89MNLqT78hap0gsBpzOyxPuV_VSg97aLw/edit#slide=id.p25
const brandSurfNTurf = '#35958A'
const brandSeaNymph = '#79A2A4'
const brandForestFern = '#529772'

const theme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#272727',
      paper: '#131919',
    },
    primary: {
      main: '#122A32',
    },
    secondary: {
      main: '#FF8A04',
    },
    text: {
      primary: '#F2F2F2'
    },
    // custom colors
    brandSurfNTurf: createColor(brandSurfNTurf),
    brandSeaNymph: createColor(brandSeaNymph),
    brandForestFern: createColor(brandForestFern),
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: 'linear-gradient(#131919, #122A32)',
          minHeight: '100vh',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'action' },
              style: {
                border: '2px solid',
                backgroundColor: 'transparent',
                borderColor: brandSurfNTurf,
                // same as text.primary
                color: '#F2F2F2',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: brandSurfNTurf,
                }
              },
            },
          ],
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        color: 'brandSurfNTurf'
      },
      styleOverrides: {
        root: {
          marginBottom: '8px',
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          margin: '4px 0px',
        },
        root: {
          '&::before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
          },
          '&.Mui-focused:after': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
          },
        },
      },
    },
  }
}))

export default theme