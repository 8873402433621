import Grid from '@mui/material/Grid2'
import React from 'react'
import MuiLink from '@mui/material/Link'
import Divider from '@mui/material/Divider'

export default function Footer() {
  return (
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          padding: '32px 0px',
        }}
        size={12}
      >
        <MuiLink
          href="https://mediatailor.fi/"
          target="_blank"
          rel="noreferrer"
          color="brandSeaNymph.main"
        >
          mediatailor.fi
        </MuiLink>
        <Divider orientation="vertical" />
        <MuiLink
          href="https://mediapocket.fi/"
          target="_blank"
          rel="noreferrer"
          color="brandSeaNymph.main"
        >
          mediapocket.fi
        </MuiLink>
    </Grid>
  )
}
