import Button from '@mui/material/Button'
import React, { useContext } from 'react'
import AuthContext from '../../../context/AuthContext'
import Typography from '@mui/material/Typography'

export default function LogoutButton() {
  const { logoutUser, user } = useContext(AuthContext)

  if (!user) return null

  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
      }}
    >
      <Typography color="brandSurfNTurf.main">
        {user.username}
      </Typography>
      <Button
        variant="string"
        onClick={logoutUser}
        data-cy="logout-button"
      >
        Log out
      </Button>
    </div>
  )
}
