import React from 'react'
import Grid from '@mui/material/Grid2'
import { ReactComponent as LogoSvg } from '../../images/logos/logo.svg'
import LogoutButton from '../components/button/LogoutButton'
import Typography from '@mui/material/Typography'

export default function Header() {
  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: '32px 0px' }} size={12}>
        <LogoSvg
          style={{
            maxWidth: '100%',
            width: '500px',
            display: 'block',
          }}
          alt="Media Tailor logo and slogan"
        />
        <Typography variant="h5" color="secondary.main" fontWeight="bold" textAlign="center">
          Thread - File Transfer Solution
        </Typography>
        <Typography textAlign="center">
          Effortless and Secure File Transfers
        </Typography>
      </Grid>
      <LogoutButton />
    </>
  )
}
