import Grid from '@mui/material/Grid2'
import PropTypes from 'prop-types'
import React from 'react'
import AddOrDeleteSegment from './AddOrDeleteSegment'
import Segment from './Segment'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

export default function SegmentFields({
  programInformation, setProgramInformation, validateAllSegments,
}) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant="h6">Segments</Typography>
          </Grid>
          <input
            type="hidden"
            id="Segment1StartHidden"
            name="Segment1Start"
            value="00:00:00:00"
          />
          {programInformation.map((segment) => (
            <Grid
              key={`segment-${segment.id}-${segment.segmentType}-container`}
              size={{
                xs: 12,
                sm: 6,
                md: 6
              }}>
              <Segment
                key={`segment-${segment.id}-${segment.segmentType}`}
                current={segment}
                programInformation={programInformation}
                setProgramInformation={setProgramInformation}
                validateAllSegments={validateAllSegments}
              />
            </Grid>
          ))}
          <AddOrDeleteSegment
            programInformation={programInformation}
            setProgramInformation={setProgramInformation}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}

SegmentFields.propTypes = {
  programInformation: PropTypes.array.isRequired,
  setProgramInformation: PropTypes.func.isRequired,
  validateAllSegments: PropTypes.func.isRequired,
}
