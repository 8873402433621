import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import CloudDoneIcon from '@mui/icons-material/CloudDone'

export default function UploadCompletedDialog({ handleClose, uppyUploadResponse }) {
  return (
    <Dialog
      open
      onClose={handleClose}
      PaperProps={{
        variant: 'outlined',
        elevation: 0,
      }}
      data-cy="upload-completed-dialog"
    >
      <DialogTitle align="center">
        Upload Success
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <CloudDoneIcon color="brandSurfNTurf" sx={{ height: '80px', width: '80px', display: 'block', margin: 'auto' }} />
        <DialogContentText align="center">
          <strong>
            Thank you!
          </strong>
          <br />
          The file has been uploaded successfully.
          <br />
          <br />
          <strong>{uppyUploadResponse.meta.house_id}</strong>
          <br />
          <span style={{ wordBreak: 'break-all' }}>
            {uppyUploadResponse.meta.name}
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          fullWidth
          color="secondary"
          onClick={handleClose}
          autoFocus
          data-cy="close-upload-completed-dialog"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UploadCompletedDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  uppyUploadResponse: PropTypes.object.isRequired
}