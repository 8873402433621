import PropTypes from 'prop-types'
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import Button from '@mui/material/Button'

export default function DeleteSegmentButton({ setProgramInformation, index }) {
  const deleteSegment = () => {
    setProgramInformation((old) => {
      const copy = [...old]
      copy.splice(index, 2)
      return copy
    })
  }

  return (
    <Button
      size="small"
      sx={{ color: 'text.secondary' }}
      fullWidth
      startIcon={<ClearIcon />}
      color="text"
      onClick={deleteSegment}
      data-cy="delete-segment-button"
    >
      Remove last segment
    </Button>
  )
}

DeleteSegmentButton.propTypes = {
  setProgramInformation: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}
